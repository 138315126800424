import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import { useDispatch } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import { getPortfolioMarkdown$, selectPortfolioMarkdown } from '../markdown/markdownSlice';
import { UgraphicCollapsible } from '../collapsible/UgraphicCollapsible';
import styles from './Portfolio.module.scss';
import { UgraphicModal } from '../modal/UgraphicModal';
import { PortfolioDetails } from '../portfolio-details/PortfolioDetails';

export function Portfolio() {
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState(<div></div>);
    const [selectedImage, setSelectedImage] = useState(0);
    const markdown = useAppSelector(selectPortfolioMarkdown);
    const [isMoreOpen, setIsMoreOpen] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getPortfolioMarkdown$());        
    }, [dispatch]);

    useEffect(() => {
        const images = document.querySelectorAll('.portfolio img');
        images.forEach((image, index) => {
            const indexToOpen = (index as number) - 1 < 0 ? 0 : (index as number) - 1;
            (image as HTMLElement).onclick = () => openModal(indexToOpen);
        });
    });

    const openModal = (index: number) => {
        let scrollPos = window.scrollY || document.getElementsByTagName("html")[0].scrollTop;
        setSelectedImage(scrollPos);
        setModalContent(<PortfolioDetails activeIndex={index} />);
        setShowModal(true);
    }

    const closeModal = () => {        
        setShowModal(false);
        window.scrollTo({
            top: selectedImage,
            behavior: 'auto'
        });
    }

    const handleToggleMore = () => {        
        setIsMoreOpen(prevOpen => !prevOpen);
    }

    return (
        <React.Fragment>
            <ReactMarkdown
                children={markdown}
                className={`${styles.portfolio__markdown} portfolio`}
                includeElementIndex
                remarkPlugins={[require('remark-heading-id')]}
                components={{
                    img({ node, index }) {
                        const { src, alt } = node.properties as { src: string, alt: string };
                        return <img src={src} alt={alt}></img>
                    },
                    blockquote({ node, className, children, index, ...props }) {
                        return <UgraphicCollapsible 
                        open={isMoreOpen} 
                        onToggle={handleToggleMore} 
                        children={children} 
                        className={styles.portfolio__arrow} />
                    }
                }}
            />
            <UgraphicModal title={''} show={showModal} handleHide={closeModal} content={modalContent} />
        </React.Fragment>
    );
}
