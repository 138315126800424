import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactNode, useRef } from "react";
import Collapsible from "react-collapsible";

export type UgraphicCollapsibleProps = {
    children: ReactNode[],
    className: string,
    open: boolean,
    onToggle: () => void
}

export const UgraphicCollapsible = ({ children, className, open, onToggle }: UgraphicCollapsibleProps) => {
    const collapsibleStart = useRef<HTMLDivElement>(null);

    const toggle = () => {
        if (open) {
            scrollToTop();
        }
        onToggle();
    }

    const scrollToTop = () => collapsibleStart.current?.scrollIntoView();

    const style = {
        transform: open ? 'rotate(180deg)' : '',
        transition: 'transform 400ms ease', // smooth transition
    }

    return (
        <React.Fragment>
            <div ref={collapsibleStart}></div>
            <Collapsible open={open} transitionTime={400} trigger={''}>
                {children}
            </Collapsible>
            {
                !open ? <div className={className}>
                    <FontAwesomeIcon onClick={toggle} style={style} icon='chevron-down' />
                    {<span>Több</span>}
                </div> : <div className={className}><FontAwesomeIcon onClick={toggle} style={style} icon='chevron-down' />
                    <span>Kevesebb</span></div>
            }
        </React.Fragment>
    )
}