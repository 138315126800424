import React, { useEffect, useRef } from 'react'
import ReactMarkdown from 'react-markdown';
import { useDispatch } from 'react-redux';
import { Carousel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CarouselRef } from 'react-bootstrap/esm/Carousel';

import { useAppDetectMobile, useAppSelector } from '../../app/hooks';
import { selectPortfolioDetailsMarkdown, getPortfolioDetailsMarkdown$ } from '../markdown/markdownSlice';

import styles from './PortfolioDetails.module.scss';
import deflist from 'remark-deflist/lib';

export type PortfolioDetailsProps = {
    activeIndex: number;
}

export const IMAGE_NAME_REGEX = /([^/]+)(?=\.\w+$)/;
export const IMAGE_URL_REGEX = /.*\/(.*)\//;
export const IMAGE_EXTENSION = /\.\w{3,4}($|\?)/;

export const PortfolioDetails = (componentProps: PortfolioDetailsProps) => {
    let isScrolling = false;
    let swipeStartPositionX = 0;
    let swipeStartPositionY = 0;
    const markdown = useAppSelector(selectPortfolioDetailsMarkdown);
    const dispatch = useDispatch();
    const isMobile = useAppDetectMobile();
    const containerRef = useRef<HTMLDivElement>(null);
    const carouselRef = useRef<CarouselRef>(null);

    useEffect(() => {
        dispatch(getPortfolioDetailsMarkdown$());
    }, [dispatch]);

    const scrollTop = () => {
        if (!!containerRef) {
            (containerRef as React.MutableRefObject<HTMLDivElement>).current.scroll(0, 0);
        }
    };

    const handleScroll = () => {
        let handle: any = null;
        if (handle) {
            clearTimeout(handle);
        }
        if (!isScrolling) {
            isScrolling = true;
        }
        handle = setTimeout(() => {
            isScrolling = false;
        }, 200);
    };

    const handleSlide = () => {
        scrollTop();
    }

    const handleTouch = (touchEvent: any) => {
        swipeStartPositionX = touchEvent.changedTouches[0].clientX;
        swipeStartPositionY = touchEvent.changedTouches[0].clientY;
    }

    const handleTouchEnd = (touchEvent: any) => {
        const directionX = (touchEvent.changedTouches[0].clientX - swipeStartPositionX);
        const directionY = Math.abs(touchEvent.changedTouches[0].clientY - swipeStartPositionY);
        if (!!carouselRef && (!isScrolling && directionY < 50)) {
            const refObject = (carouselRef as React.MutableRefObject<CarouselRef>).current;
            if (directionX) {
                directionX > 0 ? refObject.prev() : refObject.next();
            }
        }
    }

    const getHeaderFromMarkdown = () => {
        const header = markdown.match('^###.*');
        return header && header[0] ? header[0] : '';
    }

    const elements = <ReactMarkdown
        children={markdown}
        remarkPlugins={[deflist]}
        components={{
            hr() { return null },
            h3() { return null },
            blockquote({ children, ...props }) {
                return <div className={styles.carouselContainer} onScroll={handleScroll} ref={containerRef} onTouchStart={handleTouch} onTouchEnd={handleTouchEnd}>
                    <Carousel
                        ref={carouselRef}
                        nextIcon={<div className={styles.navigationIconRight}><FontAwesomeIcon icon='chevron-right' /></div>}
                        prevIcon={<div className={styles.navigationIconLeft}><FontAwesomeIcon icon='chevron-left' /></div>}
                        controls={!isMobile}
                        className={styles.custom}
                        wrap
                        indicators={false}
                        touch={false}
                        defaultActiveIndex={componentProps.activeIndex}
                        onSlide={handleSlide}
                        interval={null}>
                        {
                            children.filter(c => {
                                return typeof c === 'object' && (c as React.ReactElement)?.props?.node?.tagName === 'dl'
                            }).map(el => {
                                const element = el as React.ReactElement;
                                return <Carousel.Item
                                    key={element.key}>
                                    <div className={styles.sliderElement}>
                                        {element}
                                    </div>
                                </Carousel.Item>
                            })
                        }
                    </Carousel>
                </div>
            },
            dl({ children }) { return <div>{children}</div> },
            dt({ children }): JSX.Element { return <h2>{children}</h2> },
            img({ node }) {
                const { src, alt } = node.properties as { src: string, alt: string };
                let imageSource = src;
                if (isMobile) {
                    const imageUrl = src.match(IMAGE_URL_REGEX);
                    const imageName = src.match(IMAGE_NAME_REGEX);
                    const imageExtension = src.match(IMAGE_EXTENSION);
                    imageSource = imageUrl && imageName && imageExtension ? `${imageUrl[0]}${imageName[0]}--mobil${imageExtension[0]}` : src;
                }
                return <img src={imageSource} alt={alt}></img>;
            },
            dd({ children }) {
                const hasImage = children.some(c => (typeof c !== 'string'));
                return <p className={hasImage ? styles.imageContainer : styles.descriptionContainer}>{children}</p>
            },
        }}
    />

    return (
        <React.Fragment>
            <div className={styles.portfolioDetails}>
                <ReactMarkdown
                    children={getHeaderFromMarkdown()}
                    remarkPlugins={[require('remark-heading-id')]}
                />
                {markdown ? <div className={styles.portfolioDetails__container}>
                    {elements}
                </div> : ''}
            </div>
        </React.Fragment>
    )
}