import React, { useState } from 'react';
import styles from './AppHeader.module.scss';

export function AppHeader() {
    const [open, setOpen] = useState(false);
    const headerElements = [
        { name: 'portfólió', anchor: '#portfolio' },
        { name: 'szolgáltatás', anchor: '#szolgaltatas' },
        { name: 'rólam', anchor: '#rolam' },
        { name: 'kapcsolat', anchor: '#kapcsolat' }];
    const handleMenuChange = () => {
        setOpen(prevOpen => !prevOpen);
    }
    return (
        <React.Fragment>
            <div className={styles.header__container}>
                <div className={styles.header__logo}>
                    <a href="/#portfolio">
                        <img
                            alt='ugraphic logo'
                            src={process.env.PUBLIC_URL + '/ugraphic_logo_desktop.jpg'}
                            className={styles.header__logoDesktop} />
                    </a>
                    <a href="/#portfolio">
                        <img
                            alt='ugraphic logo'
                            src={process.env.PUBLIC_URL + '/ugraphic_logo_mobile.png'}
                            className={styles.header__logoMobile} />
                    </a>
                </div>
                <div className={styles.header__menu}>
                    <div className={styles.navigationDesktop}>
                        <nav className={`${styles.navigation__navHorizontal} row`}>
                            <ul>
                                {headerElements.map(element =>
                                    <li
                                        key={element.anchor}>
                                        <a href={element.anchor}>
                                            <span className={styles.navigation__item}>{element.name}</span>
                                        </a>
                                    </li>
                                )}
                            </ul>
                        </nav>
                    </div>
                    <div className={styles.navigationMobile}>
                        <input
                            type="checkbox"
                            onChange={handleMenuChange}
                            checked={open}
                            className={styles.navigation__checkbox}
                            id="navi-toggle" />

                        <label htmlFor="navi-toggle" className={styles.navigation__button}>
                            <span className={styles.navigation__icon1}>&nbsp;</span>
                            <span className={styles.navigation__icon2}>&nbsp;</span>
                            <span className={styles.navigation__icon3}>&nbsp;</span>
                        </label>
                        <div className={styles.navigation__background}>&nbsp;</div>
                        <nav className={styles.navigation__nav}>
                            <ul className={styles.navigation__list}>
                                {headerElements.map(element =>
                                    <li onClick={handleMenuChange} className={styles.navigation__item} key={element.anchor}>
                                        <a className={styles.navigation__link} href={element.anchor}>
                                            <span>{element.name}</span>
                                        </a>
                                    </li>
                                )}
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}