import React from "react";
import { useAppDetectMobile } from "../../app/hooks";
import styles from './AppFooter.module.scss';

export function AppFooter() {
    const isMobile = useAppDetectMobile();
    return (
        <React.Fragment>
            <div className={styles.footer}>
                <div className={styles.footer__impressum}>
                    <p><span>&copy;</span>2021 ugraphic.hu</p>
                    <p>ugraphic.u@gmail.com</p>
                </div>
                <div className={styles.footer__ajanlat}>
                    <a href='https://docs.google.com/forms/d/e/1FAIpQLSfBAlxwxJANYL9mXcY_m33xkZ7F4S28MVdcmJMoa4DWoFBaCg/viewform' target='_blank' rel='noreferrer' >
                        {isMobile ?
                            <p>ajánlat</p> :
                            <svg viewBox="0 0 100 100">
                                <path id="curve"
                                    d="M 11 50 C 11 28.46 28.46 11 50 11 C 71.54 11 89 28.46 89 50 C 89 71.54 71.54 89 50 89 M 11 50"
                                />
                                <text textLength="185px">
                                    <textPath xlinkHref="#curve">
                                        ajánlat
                                    </textPath>
                                </text>
                            </svg>
                        }
                    </a>
                </div>
            </div>
        </React.Fragment>
    )
}