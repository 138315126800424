import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from './UgraphicModal.module.scss';

export type UgraphicModalProps = {
    title: string;
    content: JSX.Element;
    show: boolean;
    handleHide: () => void
}

export const UgraphicModal = (props: UgraphicModalProps) => {
    return (
        <>
            <Modal scrollable={true} show={props.show} fullscreen={true}>
                <Modal.Body>
                    <div className={styles.modal__close}>
                        <FontAwesomeIcon onClick={() => { props.handleHide(); }} icon='times' />
                    </div>
                    {props.content}
                </Modal.Body>
            </Modal>
        </>
    );
}