import React from 'react';
import styles from './Layout.module.scss';

export interface LayoutProps {
  header: JSX.Element;
  portfolio: JSX.Element;
  designProcess: JSX.Element;
  contact: JSX.Element;
  aboutMe: JSX.Element;
  footer: JSX.Element;
}

export const Layout = ({ header, portfolio, designProcess, contact, aboutMe, footer }: LayoutProps) => {
  return (
    <React.Fragment>
      <div className={styles.layout__wrapper}>
        <div className={styles.layout__header}>{header}</div>
        <span id='portfolio' className={styles.layout__anchor}></span>
        <div className={styles.layout__portfolio}>{portfolio}</div>
        <span id='szolgaltatas' className={styles.layout__anchor}></span>
        <div className={styles.layout__design}>{designProcess}</div>
        <span id='rolam' className={styles.layout__anchor}></span>
        <div className={styles.layout__aboutMe}>{aboutMe}</div>
        <span id='kapcsolat' className={styles.layout__anchor}></span>
        <div className={styles.layout__contact}>{contact}</div>
        <div className={styles.layout__footer}>{footer}</div>
      </div>
    </React.Fragment>
  );
};
