import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../app/hooks';
import { getAboutMeMarkdown$, selectAboutMeMarkdown } from '../markdown/markdownSlice';
import styles from './AboutMe.module.scss';

export function AboutMe() {
    const markdown = useAppSelector(selectAboutMeMarkdown);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAboutMeMarkdown$());
    }, [dispatch]);

    const getHeaderFromMarkdown = () => {
        const header = markdown.match('^###.*');
        return header && header[0] ? header[0] : '';
    }
    return (
        <React.Fragment>
            <div className={styles.aboutMe__markdown}>
                <ReactMarkdown
                    children={getHeaderFromMarkdown()}
                    remarkPlugins={[require('remark-heading-id')]}
                />
                <div className={styles.aboutMe__content}>
                    <ReactMarkdown
                        children={markdown}
                        remarkPlugins={[require('remark-heading-id')]}
                        components={
                            {
                                h3() { return null }
                            }
                        }

                    />
                </div>
            </div>
        </React.Fragment>
    )
}