import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../app/hooks';
import { getDesignProcessMarkdown$, selectDesignProcessMarkdown } from '../markdown/markdownSlice';
import styles from './DesignProcess.module.scss';
import remarkGfm from 'remark-gfm';

export function DesignProcess() {
    const dispatch = useDispatch();
    const markdown = useAppSelector(selectDesignProcessMarkdown);
    useEffect(() => {
        dispatch(getDesignProcessMarkdown$())
    }, [dispatch]);

    return (
        <React.Fragment>
            <ReactMarkdown
                children={markdown}
                remarkPlugins={[require('remark-heading-id'), remarkGfm]}
                className={styles.designProcess__markdown}
                components={{
                    li({ children }) {
                        const elementKey = (children as React.ReactElement[])[1].key || Math.random() * 100;
                        return <li key={elementKey}><div className={styles.designProcess__listItem}>{children}</div></li>
                    },
                    table({ children, ...props }) {
                        const heads = new Map<number, React.ReactElement[]>();
                        children.forEach((child, mainIndex) => {
                            const childElement = (child as React.ReactElement);
                            if (childElement.type === 'thead') {
                                const headRow = childElement.props.children[0] as React.ReactElement;
                                const headList = headRow.props.children as React.ReactElement[];
                                headList.forEach((head, index) => {
                                    const renderKey = `${props.node.tagName}-${props.node.type}-${props.node.position?.start.line}-${mainIndex}-${index}`;
                                    heads.set(index, [head.props && head.props.children ?
                                        <div key={renderKey} className={`${styles.designProcess__tableCell} ${styles.designProcess__tableCellHeader}`}> {head.props.children[0]}</div>
                                        : <div key={renderKey} className={`${styles.designProcess__tableCell} ${styles.designProcess__tableCellHeader}`}></div>])
                                });

                            } else if (childElement.type === 'tbody') {
                                const bodyRows = childElement.props.children as React.ReactElement[];
                                bodyRows.forEach((row, rowIndex) => {
                                    const cells = row.props.children as React.ReactElement[];
                                    cells.forEach((cell, index) => {
                                        const renderKey = `${props.node.tagName}-${props.node.type}-${props.node.position?.start.line}-${mainIndex}-${rowIndex}-${index}`;
                                        heads.get(index)?.push(cell.props && cell.props.children ?
                                            <div key={renderKey} className={styles.designProcess__tableCell}>{cell.props.children[0]}</div> :
                                            <div key={renderKey} className={styles.designProcess__tableCell}></div>)
                                    });
                                });
                            }
                        });
                        const tableElements = [];
                        let index = 0;
                        for (let i of heads.values()) {
                            tableElements.push(
                                <div key={index} className={styles.designProcess__tableRow}>
                                    {i}
                                </div>)
                            index++;
                        }
                        return <div className={styles.designProcess__table}>
                            {tableElements}
                        </div>
                    }
                }}
            />
        </React.Fragment>
    )
}