import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../app/hooks';
import { getContactMarkdown$, selectContactMarkdown } from '../markdown/markdownSlice';
import styles from './Contact.module.scss';

export function Contact() {
    const markdown = useAppSelector(selectContactMarkdown);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getContactMarkdown$());
    }, [dispatch]);

    const getHeaderFromMarkdown = () => {
        const header = markdown.match('^###.*');
        return header && header[0] ? header[0] : '';
    }
    return (
        <React.Fragment>
            <div className={styles.contact__markdown}>
                <ReactMarkdown
                    children={getHeaderFromMarkdown()}
                    remarkPlugins={[require('remark-heading-id')]}
                />
                <div className={styles.contact__content}>
                    <ReactMarkdown
                        children={markdown}
                        remarkPlugins={[require('remark-heading-id')]}
                        components={
                            {
                                h3() { return null },
                                a({ children, ...props }) { return <a target='_blank' rel='noreferrer' href={props.href}>{children}</a> }
                            }
                        }

                    />
                </div>
            </div>
        </React.Fragment>
    )
}