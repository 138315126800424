import './App.scss';
import { Layout } from './features/layout/Layout';
import { Portfolio } from './features/portfolio/Portfolio';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronDown, faChevronLeft, faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons'
import { AppHeader } from './features/header/AppHeader';
import { DesignProcess } from './features/design-process/DesignProcess';
import { Contact } from './features/contact/Contact';
import { AboutMe } from './features/about-me/AboutMe';
import { AppFooter } from './features/footer/AppFooter';

library.add(faChevronDown, faChevronLeft, faChevronRight, faTimes);

function App() {
  return (
    <div>
      <Layout
        header={<AppHeader />}
        portfolio={<Portfolio />}
        designProcess={<DesignProcess />}
        contact={<Contact />}
        aboutMe={<AboutMe />}
        footer={<AppFooter />}
      />
    </div>
  );
}

export default App;
