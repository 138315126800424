import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { PageMarkdowns } from './markdowns.model';
import { getPortfolioMd$ } from '../portfolio/portfolio.data.service';
import { getDesignProcessMd$ } from '../design-process/design-process.data.service';
import { getPortfolioDetailsMd$ } from '../portfolio-details/portfolio-details.data.service';
import { getContactMd$ } from '../contact/contact.data.service';
import { getAboutMeMd$ } from '../about-me/about-me.data.service';
export interface MarkdownState {
  markdown: PageMarkdowns,
  status: 'idle' | 'loading' | 'failed';
}

const initialState: MarkdownState = {
  markdown: {
    portfolio: '',
    designProcess: '',
    aboutMe: '',
    contact: '',
    portfolioDetails: ''
  },
  status: 'idle',
};
export const getPortfolioMarkdown$ = createAsyncThunk(
  'markdown/fetchPortfolioMarkdown',
  async () => {
    return await getPortfolioMd$();
  }
);

export const getDesignProcessMarkdown$ = createAsyncThunk(
  'markdown/fetchDesignProcessMarkdown',
  async () => {
    return await getDesignProcessMd$();
  }
);

export const getPortfolioDetailsMarkdown$ = createAsyncThunk(
  'markdown/fetchPortfolioDetailsMarkdown',
  async () => {
    return await getPortfolioDetailsMd$();
  }
);

export const getContactMarkdown$ = createAsyncThunk(
  'markdown/fetchContactMarkdown',
  async () => {
    return await getContactMd$();
  }
);

export const getAboutMeMarkdown$ = createAsyncThunk(
  'markdown/fetchAboutMeMarkdown',
  async () => {
    return await getAboutMeMd$();
  }
);

export const markdownSlice = createSlice({
  name: 'markdown',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPortfolioMarkdown$.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getPortfolioMarkdown$.fulfilled, (state, action) => {
        state.status = 'idle';
        state.markdown.portfolio = action.payload;
      })
      .addCase(getPortfolioMarkdown$.rejected, (state, action) => {
        state.status = 'failed';
        console.log(action.error);
      })
      .addCase(getDesignProcessMarkdown$.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getDesignProcessMarkdown$.fulfilled, (state, action) => {
        state.status = 'idle';
        state.markdown.designProcess = action.payload;
      })
      .addCase(getDesignProcessMarkdown$.rejected, (state, action) => {
        state.status = 'failed';
        console.log(action.error);
      })
      .addCase(getPortfolioDetailsMarkdown$.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getPortfolioDetailsMarkdown$.fulfilled, (state, action) => {
        state.status = 'idle';
        state.markdown.portfolioDetails = action.payload;
      })
      .addCase(getPortfolioDetailsMarkdown$.rejected, (state, action) => {
        state.status = 'failed';
        console.log(action.error);
      })
      .addCase(getContactMarkdown$.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getContactMarkdown$.fulfilled, (state, action) => {
        state.status = 'idle';
        state.markdown.contact = action.payload;
      })
      .addCase(getContactMarkdown$.rejected, (state, action) => {
        state.status = 'failed';
        console.log(action.error);
      })
      .addCase(getAboutMeMarkdown$.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAboutMeMarkdown$.fulfilled, (state, action) => {
        state.status = 'idle';
        state.markdown.aboutMe = action.payload;
      })
      .addCase(getAboutMeMarkdown$.rejected, (state, action) => {
        state.status = 'failed';
        console.log(action.error);
      })
  },
});

export const selectPortfolioMarkdown = (state: RootState) =>
  state.markdowns.markdown.portfolio;

export const selectDesignProcessMarkdown = (state: RootState) =>
  state.markdowns.markdown.designProcess;

export const selectPortfolioDetailsMarkdown = (state: RootState) =>
  state.markdowns.markdown.portfolioDetails;

export const selectContactMarkdown = (state: RootState) =>
  state.markdowns.markdown.contact;

export const selectAboutMeMarkdown = (state: RootState) =>
  state.markdowns.markdown.aboutMe;

export default markdownSlice.reducer;
